import React from 'react';
import { Header, Icon, Segment } from 'semantic-ui-react';

function PropertyAsCollateralWarning() {
    return (
        <div className="ui segments">
            <div className="property-collateral-warning">
                <Segment.Group>
                    <Segment>
                        <Header as="h4">
                            <Icon name="home" />
                            <Header.Content>Property as security</Header.Content>
                        </Header>
                    </Segment>
                    <Segment>No property has been added as a potential security for this application</Segment>
                </Segment.Group>
            </div>
        </div>
    );
}

export default PropertyAsCollateralWarning;

import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';

/**
 * About:
 * LinkWrapper was created because we are using a old version of Semantic UI which has problems using the <Link> of React Router DOM.
 * The solution wrap the component into a generic component and manually passing the props as arguments of Link and the React dondes as its content inside tags <>.
 */
export default function LinkWrapper({ children, ...props }) {
    return <Link {...props}>{children}</Link>;
}

LinkWrapper.propTypes = {
    /** Properties commonly send to the links or buttons */
    props: PropTypes.shape({
        /** The destination of the link or button event */
        to: PropTypes.oneOfType([
            PropTypes.string, // Example: "/about"
            PropTypes.object, // Example: { pathname: "/about", search: "?id=123" }
        ]).isRequired,
        /** CSS class for styling */
        className: PropTypes.string,
        /** Additional button events */
        onClick: PropTypes.func,
    }),
    /** React nodes (components) rendered inside */
    children: PropTypes.node.isRequired,
};

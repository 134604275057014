import './style.css';

import PropertyQuestions from 'APP/components/Application/View/PropertyQuestions';
import OfferStates from 'APP/enums/Offer/States';
import ResponseStatuses from 'APP/enums/Response/Statuses';
import ResponseTypes from 'APP/enums/Response/Types';
import { HandleException } from 'APP/helpers/ErrorHelper';
import ApplicationHeader from 'APP/pages/PartnerApplication/View/AppliationHeader';
import StepIndicator from 'APP/pages/PartnerApplication/View/StepIndicator';
import pathToRegexp from 'path-to-regexp';
import queryString from 'query-string';
import React, { Component } from 'react';
import { Header, Segment } from 'semantic-ui-react';

import {
    OffersAction,
    PropertyQuestionActions,
    TreatApplicationsActions as PartnerApiActions,
} from '../../../actions/PartnerApiActions/index';
import Scroller from '../../../common/Scroller';
import Toast from '../../../common/Toast';
import ApplicationWrapper from '../../../components/Application/View/Business/ApplicationWrapper';
import GeneralLoader from '../../../components/GeneralLoader';
import TreatApplicationForm from '../../../components/PartnerApplication/TreatForm/index';
import ClipboardHelper from '../../../helpers/ClipboardHelper';
import { isEmpty } from '../../../helpers/CommonHelper';
import ProductHelper from '../../../helpers/ProductHelper';
import ResponseHelper from '../../../helpers/ResponseHelper';
import AcceptedOffer from './AcceptedOffer';
import AcceptedWithdrawnMessage from './AcceptedOfferWithdrawnMessage';
import ApplicantCardsWrapper from './ApplicantCardsWrapper';
import BusinessRevisions from './Business/Revisions';
import PropertyAsCollateralWarning from './Business/Revisions/PropertyAsCollateralWarning';
import OfferModal from './OfferModal';
import RevisionChanges from './RevisionChanges/RevisionChanges';
import Revisions from './Revisions/Revisions';
import WonApplicationActions from './WonApplicationActions';

// Actions

const TreatFormBasedOn = {
    PRODUCT_SETTINGS: 'productSettings',
    PREVIOUS_LOAN_OFFER: 'previousLoanOffer',
    DRAFT_LOAN_OFFER: 'draftLoanOffer',
};

const createComplementsForApplicant = (applicantId, complements) =>
    Object.entries(complements)
        .filter(([value]) => value)
        .map(([key, value]) => ({
            type: key,
            value: value.toString(),
            applicant_id: applicantId,
        }));

class ApplicationView extends Component {
    constructor(props) {
        super(props);

        this.state = this.initalState(props);

        this.rerouteInModal = this.rerouteInModal.bind(this);
    }

    initalState = (props = this.props) => {
        const queries = queryString.parse(props.location.search);
        const productId = queries.product_id || props.match.params.productId;

        return {
            applicationId: props.match.params.id,
            productId,
            productSettings: null,
            application: null,
            showCreateOffer: false,
            offer: {},
            initialValuesSetFrom: TreatFormBasedOn.PRODUCT_SETTINGS,
            initialValues: {},
            treatFormEnabled: false,
            previousRevisions: [],
            responses: [],
            propertyQuestions: [],
        };
    };

    sendOffer = async (data) => {
        try {
            const dataCopy = { ...data };
            const isBusiness = ProductHelper.isBusinessLoan(this.state.application.products[0]);

            // Build complements
            if (dataCopy.complements) {
                if (isBusiness) {
                    dataCopy.complements = createComplementsForApplicant(
                        this.state.application.applicants[0].id,
                        dataCopy.complements
                    );
                } else {
                    // Collect the complements for both applicants and build array of complement objects
                    dataCopy.complements = Object.entries(dataCopy.complements)
                        .map(([applicantID, applicantComplements]) =>
                            createComplementsForApplicant(applicantID, applicantComplements)
                        )
                        .reduce((x, y) => [...x, ...y], []); // Flatten
                }
                // Make sure we don't send any {value: "false"} complements
                dataCopy.complements = dataCopy.complements.filter((c) => c.value !== 'false');
            } else {
                dataCopy.complements = [];
            }

            // If we have annuity payments, we have the same monthly cost first and last month
            if (dataCopy.repayment_type === 'annuity' && !dataCopy.monthly_cost_last) {
                dataCopy.monthly_cost_last = dataCopy.monthly_cost_first;
            }

            if (dataCopy.administration_fee === '') {
                dataCopy.administration_fee = 0;
            }
            if (dataCopy.autogiro_fee === '') {
                dataCopy.autogiro_fee = 0;
            }
            if (dataCopy.setup_fee === '') {
                dataCopy.setup_fee = 0;
            }
            if (dataCopy.invoice_fee === '') {
                dataCopy.invoice_fee = 0;
            }

            if (isBusiness && dataCopy.business_loan_type === 'none_of_the_above') {
                delete dataCopy.business_loan_type;
            }
            const offer = { status: ResponseStatuses.ACTIVE, ...dataCopy };

            const type = isBusiness ? ResponseTypes.BUSINESS_LOAN_OFFER : ResponseTypes.PRIVATE_LOAN_OFFER;

            if (offer.draft_response_id) {
                await OffersAction.patchOffer({ type: offer.type, id: offer.draft_response_id }, offer);
            } else {
                await OffersAction.create(offer, type);
            }

            Toast('success', 'Offer created');
            this.rerouteToList();
        } catch (err) {
            HandleException(err);
        }
    };

    closeOfferModal = () => this.setState(() => ({ show_create_offer: !this.state.show_create_offer }));

    copy = () => ClipboardHelper.copy(this.state.offer.id);

    fetchInitialData = async () => {
        await this.fetchData();
        this.fetchPropertyQuestions();

        // So the state has been updated
        setTimeout(() => {
            this.initializeTreatForm();
        }, 0);
    };

    fetchPropertyQuestions = async () => {
        const { applicationId } = this.state;
        const propertyQuestionsData = await PropertyQuestionActions.getBusinessPropertyQuestions(applicationId);
        this.fillAndSetPropertyQuestions(propertyQuestionsData);
    };

    fillAndSetPropertyQuestions = (propertyQuestionsData) => {
        if (
            this.state.application.company &&
            this.state.application.company.credit_data &&
            this.state.application.company.credit_data.estates &&
            this.state.application.company.credit_data.estates.length > 0
        ) {
            this.state.application.company.credit_data.estates.forEach((estate) => {
                let added = false;
                propertyQuestionsData.forEach((propertyQuestion) => {
                    if (estate.property_label === propertyQuestion.property_name) {
                        added = true;
                        return;
                    }
                });

                if (!added) {
                    const property = {
                        applicationID: this.state.application.id,
                        loan_amount: null,
                        market_value: null,
                        property_name: estate.property_label,
                    };

                    propertyQuestionsData.push(property);
                }
            });
        }

        this.setState({ propertyQuestions: propertyQuestionsData });
    };

    fetchData = async () => {
        const { applicationId, productId } = this.state;

        try {
            const data = await PartnerApiActions.getApplication(applicationId, productId);
            const application = data.revisions.reduce((a, b) => (a.revision > b.revision ? a : b));
            const previousRevisions = data.revisions.filter((rev) => rev.revision !== application.revision);
            const prevRevision = previousRevisions.reduce(
                (a, b) => (a.revision > b.revision ? a : b),
                previousRevisions[0]
            );
            const acceptance = data.acceptance; // Get acceptance object
            const acceptanceAvailable = !isEmpty(data.acceptance); // Check if acceptance is available
            let acceptanceResponse;
            let activeAcceptedResponse;
            let withDrawnAcceptedOffer;
            let customerAcceptedOffer;
            let billOfDebtSent;
            let loanPaidOut;

            if (acceptanceAvailable) {
                acceptanceResponse = acceptance.responses.find(({ response }) => ResponseHelper.isLoanOffer(response)); // Get correct response associated with accept
                activeAcceptedResponse = data.responses.find(
                    (response) => response.id === acceptanceResponse.response.id
                ); // Filter out correct response associated with accept response id
                withDrawnAcceptedOffer = activeAcceptedResponse.status === ResponseStatuses.WITHDRAWN;
                customerAcceptedOffer = activeAcceptedResponse.state === OfferStates.LOAN_OFFERED; // Boolean  if customer has accepted offer given
                billOfDebtSent =
                    activeAcceptedResponse.state === OfferStates.CONTRACT_SENT ||
                    activeAcceptedResponse.state === OfferStates.CONTRACT_SIGNED; // Boolean if client has sent bill of debt

                loanPaidOut = activeAcceptedResponse.state === OfferStates.LOAN_PAID; // Boolean if partner has bill of debt paid out
            }

            const allRevisions = data.revisions;
            const submitDisabled = data.responses
                .filter((r) => r.revision === application.revision)
                .some(ResponseHelper.isActive);

            this.setState({
                productSettings: data.product_settings,
                application,
                previousRevisions,
                responses: data.responses,
                allRevisions,
                prevRevision,
                submitDisabled,
                acceptanceAvailable,
                activeAcceptedResponse,
                customerAcceptedOffer,
                billOfDebtSent,
                loanPaidOut,
                product: data.product,
                withDrawnAcceptedOffer,
                acceptance: data.acceptance,
            });
        } catch (e) {
            HandleException(e);
        }
    };

    initializeTreatForm() {
        const { application, previousRevisions, acceptanceAvailable, responses, withDrawnAcceptedOffer } = this.state;

        // Do not show if there is an acceptance available
        if (acceptanceAvailable && !withDrawnAcceptedOffer) {
            return;
        }

        // If a draft exist
        if (responses.some((r) => ResponseHelper.isLoanOffer(r) && r.status === ResponseStatuses.DRAFT)) {
            this.enableTreatForm(TreatFormBasedOn.DRAFT_LOAN_OFFER)();
            return;
        }

        // For private, we always show this form
        const product = application?.products?.[0];
        if (product && ProductHelper.isPrivateLoan(product)) {
            this.enableTreatForm(TreatFormBasedOn.PRODUCT_SETTINGS)();
            return;
        }

        // We also show it if we don't have any active responses
        if (previousRevisions.length === 0 && !responses.some(ResponseHelper.isActive)) {
            this.enableTreatForm(TreatFormBasedOn.PRODUCT_SETTINGS)();
        }

        // Otherwise, don't initialize the form.
    }

    getLatestLoanOffer = () => {
        const loanOffers = this.state.responses.filter(ResponseHelper.isLoanOffer); // Get active loan offers (business can have multiple)
        if (!loanOffers.length) {
            return null;
        }
        return loanOffers.reduce((a, b) => (a.created_at > b.created_at ? a : b));
    };

    getLatestDraftLoanOffer = () => {
        const loanOffers = this.state.responses.filter(
            (r) => ResponseHelper.isLoanOffer(r) && r.status === ResponseStatuses.DRAFT
        );
        if (!loanOffers.length) {
            return null;
        }
        return loanOffers.reduce((a, b) => (a.created_at > b.created_at ? a : b));
    };

    getTreatFormInitialValues = (type) => {
        const { application, productId, productSettings } = this.state;
        const isBusiness = application?.products?.[0] && ProductHelper.isBusinessLoan(application.products[0]);

        let initialValues = {
            revision: application?.revision,
            product_id: productId,
            application_id: application?.id,
            application_type: application?.type,
            amount: application?.products[0].total_loan_amount,
            amortize_length: application?.products[0].amortize_length,
            administration_fee: productSettings?.admin_fee,
            invoice_fee: productSettings?.invoice_fee,
            ...productSettings,
        };

        /* 
            We need to send this field for business offer. 
            It's not required and BE will assign a default value on it after submit.
        */
        if (isBusiness) {
            initialValues = {
                ...initialValues,
                business_loan_type: null,
            };
        }

        if (type === TreatFormBasedOn.PREVIOUS_LOAN_OFFER) {
            const loanOffer = this.getLatestLoanOffer();
            if (loanOffer) {
                initialValues = {
                    ...initialValues,
                    ...this.initalValuesFromLoanOffer(loanOffer),
                    ...this.initalValuesFromApplication(application),
                    set_from_previus_offer: true,
                };
            }
        } else if (type === TreatFormBasedOn.DRAFT_LOAN_OFFER) {
            const draftLoanOffer = this.getLatestDraftLoanOffer();
            if (draftLoanOffer) {
                initialValues = {
                    ...initialValues,
                    ...this.initalValuesFromLoanOffer(draftLoanOffer),
                    draft_response_id: draftLoanOffer.id,
                    business_loan_type: draftLoanOffer.business_loan_type,
                };
            }
        }

        Reflect.deleteProperty(initialValues, 'admin_fee');
        return initialValues;
    };

    initalValuesFromLoanOffer = (loanOffer) => {
        const complements = {};

        loanOffer.complements.forEach((c) => {
            if (c.applicant_id) {
                if (!complements[c.applicant_id]) {
                    complements[c.applicant_id] = {};
                }
                complements[c.applicant_id][c.type] = c.value || true;
            } else {
                complements[c.type] = true;
            }
        });

        return {
            type: loanOffer.type,
            amount: loanOffer.amount,
            amortize_length: loanOffer.amortize_length,

            administration_fee: loanOffer.administration_fee,
            invoice_fee: loanOffer.invoice_fee,
            early_redemption_fee: loanOffer.early_redemption_fee,
            autogiro_fee: loanOffer.autogiro_fee,
            setup_fee: loanOffer.setup_fee,

            interest_rate_nominal: loanOffer.interest_rate_nominal,
            interest_rate_effective: loanOffer.interest_rate_effective,
            monthly_cost_first: loanOffer.monthly_cost_first,
            monthly_cost_last: loanOffer.monthly_cost_last,
            total_cost: loanOffer.total_cost,
            repayment_type: loanOffer.repayment_type,

            amount_to_solve_external: loanOffer.amount_to_solve_external,
            amount_to_solve_internal: loanOffer.amount_to_solve_internal,

            complements,
        };
    };

    initalValuesFromApplication = (application) => ({
        amount: application.products[0].total_loan_amount,
        amortize_length: application.products[0].amortize_length,
    });

    enableTreatForm =
        (type, clicked = false) =>
        () => {
            this.setState(
                {
                    initialValues: this.getTreatFormInitialValues(type),
                    initialValuesSetFrom: type,
                    treatFormEnabled: true,
                },
                () => {
                    if (clicked) {
                        Scroller.scrollToBottom();
                    }
                }
            );
        };

    componentDidMount() {
        this.fetchInitialData();
    }

    rerouteToList = (filterID) => {
        // If we came from the list, we use those query params (Also a necessity for reroute back to search)
        const queryParams = (this.props.location.state && this.props.location.state.listQueryParams) || {};
        queryParams.filterID = filterID;

        const newPathPattern = this.props.match.path.slice(0, this.props.match.path.lastIndexOf('/'));
        const toPath = pathToRegexp.compile(newPathPattern);
        const newLocation = toPath(this.props.match.params);

        this.props.history.push({
            pathname: newLocation,
            search: queryString.stringify(queryParams),
        });
    };

    reload = () => {
        this.setState(this.initalState(), this.fetchInitialData);
    };

    rerouteInModal = () => {
        this.closeOfferModal();
        this.props.history.push({
            pathname: `/treatapplications/${this.state.nextApplication}`,
            search: this.props.location.search,
        });
    };

    getLatestResponse() {
        const activeResponsesToCurrentRevision = this.state.responses
            .filter((r) => r.revision === this.state.application.revision)
            .filter(ResponseHelper.isActive);

        if (activeResponsesToCurrentRevision.length === 0) {
            return null;
        }

        return activeResponsesToCurrentRevision.reduce((a, b) => (a.created_at > b.created_at ? a : b));
    }

    render() {
        if (!this.state.application) {
            return <GeneralLoader />;
        }

        const {
            application,
            previousRevisions,
            responses,
            allRevisions,
            prevRevision,
            submitDisabled,
            activeAcceptedResponse,
            acceptanceAvailable,
            customerAcceptedOffer,
            billOfDebtSent,
            loanPaidOut,
            product,
            withDrawnAcceptedOffer,
            acceptance,
            propertyQuestions,
        } = this.state;
        const isBusiness = ProductHelper.isBusinessLoan(application.products[0]);
        const MINIMUM_THRESHOLD_FOR_COLLATERAL = 500_000;
        const totalLoanAmount = application.products[0].total_loan_amount;
        const hasProperties = propertyQuestions.length > 0;
        const showPropertiesAsCollateral = hasProperties && totalLoanAmount >= MINIMUM_THRESHOLD_FOR_COLLATERAL;

        return (
            <Segment basic id="ApplicationView">
                <ApplicationHeader application={application} rerouteToList={this.rerouteToList} />
                <StepIndicator
                    billOfDebtSent={billOfDebtSent}
                    loanPaidOut={loanPaidOut}
                    hasAcceptance={acceptanceAvailable}
                    withDrawnAcceptedOffer={withDrawnAcceptedOffer}
                    responseGivenToLatestRevision={this.getLatestResponse()}
                />
                {isBusiness ? (
                    <ApplicationWrapper canEditBeneficialOwners={false} application={application} />
                ) : (
                    <ApplicantCardsWrapper applicants={application.applicants} />
                )}
                {isBusiness &&
                    (showPropertiesAsCollateral ? (
                        <div className="property-questions-partner">
                            <PropertyQuestions
                                application={application}
                                propertyQuestions={propertyQuestions}
                                allowEdit={false}
                            />
                        </div>
                    ) : (
                        <PropertyAsCollateralWarning />
                    ))}
                {isBusiness && (
                    <BusinessRevisions
                        disableButtons={acceptanceAvailable}
                        productId={this.state.productId}
                        revisions={allRevisions}
                        responses={responses}
                        application={application}
                        denyCallback={this.rerouteToList}
                        sendNewOfferCallback={this.enableTreatForm(TreatFormBasedOn.PREVIOUS_LOAN_OFFER, true)}
                        resendOfferCallback={this.rerouteToList}
                        withdrawOfferCallback={this.fetchInitialData}
                    />
                )}
                {!isBusiness && (previousRevisions.length > 0 || withDrawnAcceptedOffer || responses.length > 0) && (
                    <Segment>
                        <Header>Revision history</Header>
                        {previousRevisions.length > 0 && (
                            <RevisionChanges application={application} prevRevision={prevRevision} />
                        )}
                        <Revisions
                            application={application}
                            responses={responses}
                            revisions={allRevisions}
                            isBusiness={isBusiness}
                            withdrawOfferCallback={this.fetchData}
                            disableActions={acceptanceAvailable}
                        />
                    </Segment>
                )}
                {withDrawnAcceptedOffer && <AcceptedWithdrawnMessage />}
                {this.state.treatFormEnabled && (
                    <TreatApplicationForm
                        applicants={application.applicants}
                        initialValues={this.state.initialValues}
                        isBusiness={isBusiness}
                        offerAction={this.sendOffer}
                        submitDisabled={submitDisabled}
                        denyCallback={this.rerouteToList}
                        responseGivenToLatestRevision={this.getLatestResponse()}
                        resetFromDraft={this.enableTreatForm(TreatFormBasedOn.PRODUCT_SETTINGS)}
                    />
                )}
                {acceptanceAvailable && !withDrawnAcceptedOffer && (
                    <Segment.Group>
                        <Segment>
                            <AcceptedOffer
                                offer={activeAcceptedResponse}
                                product={product}
                                application={application}
                                acceptance={acceptance}
                            />
                        </Segment>
                        <Segment clearing>
                            <WonApplicationActions
                                activeOffer={activeAcceptedResponse}
                                customerAcceptedOffer={customerAcceptedOffer}
                                billOfDebtSent={billOfDebtSent}
                                loanPaidOut={loanPaidOut}
                                rerouteToList={this.rerouteToList}
                                withdrawOfferCallback={this.reload}
                                applicationID={application.id}
                                isBusiness={isBusiness}
                            />
                        </Segment>
                    </Segment.Group>
                )}
                <OfferModal
                    header={this.state.offer.header}
                    open={this.state.showCreateOffer}
                    offerId={this.state.offer.id}
                    expiresAt={this.state.offer.expires_at}
                    nextApplication={this.state.nextApplication}
                    onSubmit={this.rerouteInModal}
                    onClose={this.closeOfferModal}
                />
            </Segment>
        );
    }
}

export default ApplicationView;
